import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { retry } from "rxjs/operators";

export interface AppConfig {
  domainConfigs: { [domain: string]: DomainConfig };
  environment: string;
}

export interface DomainConfig {
  name: string;
  apiUrl: string;
  landingUrl: string;
  logoUrl: string;
  theme: "green" | "blue" | "red" | "pink" | "purple" | "grey";
  rpayAuthKey: string;
  rpayOrganizationName: string;
  rpayOrganizationEmail: string;
  settings: {
    name: string;
    theme: "green" | "blue" | "red" | "pink" | "purple" | "grey";
    rtl: boolean;
    adminSidenavIsOpened: boolean;
    adminSidenavIsPinned: boolean;
    adminSidenavUserBlock: boolean;
  };
  dev_url_prefix: string;
  dev_url_prefix_static: string;
  dev_Supporturl_prefix: string;
  settingsObject: Settings;
  stripePublishableKey: string;
}

export class Settings {
  constructor(
    public name: string,
    public theme: string,
    public rtl: boolean,
    public adminSidenavIsOpened: boolean,
    public adminSidenavIsPinned: boolean,
    public adminSidenavUserBlock: boolean
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  appConfig: AppConfig;
  currentConfig: DomainConfig;
  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) document: Document,
    private titleService: Title
  ) { }

  async loadAppConfig() {
    try {
      const origin = document.defaultView.document.location.origin;
      this.appConfig = await this.httpClient
        .get<AppConfig>("app.config.json")
        .pipe(retry(2))
        .toPromise();
      this.currentConfig = this.appConfig.domainConfigs[origin];

      this.currentConfig.logoUrl =
        this.currentConfig.apiUrl + "/frontend-assets/images/logo.png";

      this.currentConfig["dev_url_prefix"] = this.currentConfig.apiUrl + "/";
      this.currentConfig["dev_url_prefix_static"] =
        this.currentConfig.apiUrl + "/";
      this.currentConfig["dev_Supporturl_prefix"] =
        this.currentConfig.apiUrl + "/";

      this.currentConfig.settings = {
        name: this.currentConfig.name,
        theme: this.currentConfig.theme,
        rtl: false,
        adminSidenavIsOpened: true,
        adminSidenavIsPinned: true,
        adminSidenavUserBlock: true,
      };

      this.currentConfig.settingsObject = new Settings(
        this.currentConfig.settings.name,
        this.currentConfig.settings.theme,
        this.currentConfig.settings.rtl,
        this.currentConfig.settings.adminSidenavIsOpened,
        this.currentConfig.settings.adminSidenavIsPinned,
        this.currentConfig.settings.adminSidenavUserBlock
      );
      console.log(this.currentConfig);

      this.postAppConfigTasks();
    } catch (error) {
      console.log("error in loading app.config.json", error);
      throw error;
    }
  }

  postAppConfigTasks() {
    this.titleService.setTitle(this.currentConfig.name);
  }
}
