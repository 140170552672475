import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService, DomainConfig } from '../app-config.service';
import { Globals } from '../globals';
import { map } from "rxjs/internal/operators/map";

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    currentConfig: DomainConfig;
    private stripePaymentContext: any = null;

    constructor(
        private appConfigService: AppConfigService,
        private http: HttpClient,
        private globals: Globals
    ) {
        this.currentConfig = this.appConfigService.currentConfig;
    }
    httpOptions = {
        headers: new HttpHeaders({
            Accept: "application/json",
            //'Content-Type':'application/x-www-form-urlencoded',
            "Client-Service": "frontend-client",
            "Auth-key": "simplerestapi",
            "User-ID": this.globals.MemberID,
        }),
    };

    createPaymentIntent(amount: string): Observable<any> {
        console.log("Inside createPaymentIntent");
        console.log(amount);
        return this.http
            .post(
                this.currentConfig.dev_url_prefix +
                "Api/v1/Stripe/createPaymentIntent",
                { amount: amount, currency: "inr" },
                this.httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    confirmPayment(paymentIntentId: string, paymentMethodId: string): Observable<any> {
        return this.http.post(this.currentConfig.dev_url_prefix + `/confirm-payment`, {
            paymentIntentId,
            paymentMethodId,
        });
    }

    setStripePaymentContext(context: {
        serviceId: string,
        orgId: string,
        amount: number
        returnUrl: string,
    }) {
        this.stripePaymentContext = context;
    }

    getStripePaymentContext() {
        return this.stripePaymentContext;
    }

    clearStripePaymentContext() {
        this.stripePaymentContext = null;
    }

}   