import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaymentTriggerService {
    private paymentSubject = new Subject<void>();

    triggerPayment$ = this.paymentSubject.asObservable();

    triggerPayment() {
        this.paymentSubject.next();
    }
}