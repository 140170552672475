// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // dev_url_prefix: "https://workers.peoplenet.in/",
  // dev_url_prefix_static: "https://workers.peoplenet.in/",
  // dev_Supporturl_prefix: "https://workers.peoplenet.in/",

  // rpayAuthKey: "rzp_live_wucfwMUNf9EzA3",
  // rpayOrganizationName: "Peoplenet",
  // rpayOrganizationEmail: "contact@peoplenet.in",


  dev_url_prefix: "",
  dev_url_prefix_static: "",
  dev_Supporturl_prefix: "",

  rpayAuthKey: "",
  rpayOrganizationName: "",
  rpayOrganizationEmail: "",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zosne.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
